import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';

const modules = [
	MatButtonModule,
	MatIconModule,
	MatToolbarModule,
	MatDividerModule,
	MatSidenavModule,
	MatTabsModule
];

@NgModule({
	imports: modules,
	exports: modules
})
export class ContentMaterialModule {}
