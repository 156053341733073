import {
	AfterViewInit,
	Component,
	DestroyRef,
	signal,
	viewChild,
	ViewContainerRef,
	ViewEncapsulation
} from '@angular/core';
import { MatDrawer } from '@angular/material/sidenav';
import { GlobalDrawerService } from './global-drawer.service';

@Component({
	selector: 'app-global-drawer',
	templateUrl: './global-drawer.component.html',
	styleUrl: './global-drawer.component.scss'
})
export class GlobalDrawerComponent implements AfterViewInit {
	drawerContentViewRef = viewChild.required('drawerContent', { read: ViewContainerRef });

	constructor(private _destroyRef: DestroyRef, private _drawerService: GlobalDrawerService) {
		this._destroyRef.onDestroy(() => this._drawerService.unregisterView());
	}

	ngAfterViewInit(): void {
		this._drawerService.registerView(this.drawerContentViewRef());
	}
}
