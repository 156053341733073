import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ShimmerModule } from '@soleran/ngx-common';
import { ThemeService } from './theme.service';
import { StyleManagerService } from './style-manager.service';
import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';

@NgModule({
	declarations: [],
	exports: [],
	imports: [CommonModule, ShimmerModule],
	providers: [ThemeService, StyleManagerService, provideHttpClient(withInterceptorsFromDi())]
})
export class ThemeModule {}
