import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { authGuard } from '@soleran/ngx-auth';
import { DefaultLayoutComponent } from './shared/default-layout.component';

//TODO; Update auth state change refresh, base off route data field?
//If '!data.isReactive' redirect to home?
const routes: Routes = [
	{
		path: 'login',
		loadChildren: () => import('./pages/login/login-page.module').then((m) => m.LoginPageModule)
	},
	{
		path: '',
		component: DefaultLayoutComponent,
		canActivate: [authGuard],
		children: [
			{ path: '', redirectTo: 'home', pathMatch: 'full' },
			{
				path: 'home',
				loadChildren: () => import('./pages/home/home-page.module').then((m) => m.HomePageModule)
			},
			{
				path: 'chart',
				loadChildren: () => import('./pages/chart/chart-page.module').then((m) => m.ChartPageModule)
			},
			{
				path: 'user',
				loadChildren: () =>
					import('./pages/user/user-page.module').then((m) => m.UserPageModule)
			},
			{
				path: 'account',
				loadChildren: () =>
					import('./pages/account/account-page.module').then((m) => m.AccountPageModule)
			},
			{
				path: 'report',
				loadChildren: () => import('./pages/report/report.module').then((m) => m.ReportPageModule)
			},
			{
				path: 'object',
				loadChildren: () =>
					import('./pages/module/modules-page.module').then((m) => m.ModulesPageModule)
			},
			{
				path: 'help',
				loadChildren: () => import('./pages/support/support.module').then((m) => m.SupportModule)
			},
			{
				path: 'admin',
				loadChildren: () => import('./pages/admin/admin.module').then((m) => m.AdminModule)
			},
			{
				path: 'dashboard',
				loadChildren: () => import('./pages/dashboard/dashboard.module').then((m) => m.DashboardModule)
			}
		]
	},
	{ path: '**', redirectTo: '' } //404
];

@NgModule({
	imports: [
		RouterModule.forRoot(routes, {
			// enableTracing: true,
			bindToComponentInputs: true
		})
	],
	exports: [RouterModule]
})
export class AppRoutingModule {}
