import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { GlobalDrawerComponent } from './global-drawer.component';
import { GlobalDrawerMaterialModule } from './global-drawer.module.material';

const components = [GlobalDrawerComponent];

@NgModule({
	declarations: components,
	imports: [CommonModule, GlobalDrawerMaterialModule],
	exports: components
})
export class GlobalDrawerModule {}
