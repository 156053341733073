import { Component, DestroyRef, NgZone } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { fontLoaded, IClientUserToken } from '@soleran/contracts';
import { AuthService } from '@soleran/ngx-auth';
import { UserPasswordDialogComponent } from '@soleran/ngx-auth-utility';
import {
	Observable,
	delay,
	first,
	forkJoin,
	fromEventPattern,
	map,
	of,
	race,
	share,
	startWith,
	switchMap,
	take,
	timer
} from 'rxjs';

@Component({
	selector: 'app-root',
	styles: `
		.loading {
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			gap: 2rem;
			width: 100%;
			height: 100%;
		}
	`,
	template: `
		@if(!(loading$ | async)) {
		<router-outlet />
		} @else {
		<div class="loading">
			<mat-spinner />
			<h3>Loading...</h3>
		</div>
		}
	`
})
export class AppComponent {
	loading$: Observable<boolean> = of(true);

	//!TEMP!
	private _temp: IClientUserToken | null;

	constructor(
		router: Router,
		destroyRef: DestroyRef,
		zone: NgZone,
		dialogService: MatDialog,
		authService: AuthService
	) {
		this.loading$ = forkJoin([
			authService.onCurrentUser.pipe(delay(2000), first()),
			fontLoaded('Roboto'),
			fontLoaded('Material Icons')
		]).pipe(
			map(() => false),
			startWith(true),
			share()
		);
		//!TEMP!
		//TODO; Temporary solution to components not updating, permanent solution should be to update relevant components to watch the thing they are dependant on one way or another and update dynamically.
		authService.onCurrentUser.pipe(takeUntilDestroyed(destroyRef)).subscribe((user) => {
			console.warn('Temporary Account Change Handler - REPLACE WITH REACTIVE DESIGN');
			if (!this._temp || !this._temp.claims) this._temp = user;
			else if (!!user?.claims && this._temp.claims.account_id != user.claims.account_id) {
				router.navigate(['/']);
			}
		});
		authService.onPasswordReset.pipe(takeUntilDestroyed(destroyRef)).subscribe((user) => {
			if (dialogService.openDialogs.length == 0) {
				zone.run(() =>
					dialogService.open(UserPasswordDialogComponent, {
						data: { userId: user.claims.user_id, email: user.claims.email },
						autoFocus: false,
						closeOnNavigation: false,
						disableClose: true
					})
				);
			}
		});
	}
}
