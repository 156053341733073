import {
	Component,
	DestroyRef,
	effect,
	inject,
	Input,
	signal,
	viewChild,
	ViewEncapsulation
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { DirectoryItemType, DirectoryRoot } from '@soleran/contracts';
import {
	ChartBuilderComponent,
	ChartComponent,
	ChartConfig,
	ChartConfigService,
	ChartDepth
} from '@soleran/ngx-chart';
import { DirectoryService } from '@soleran/ngx-directory';
import { map, of, switchMap } from 'rxjs';
import { GlobalDrawerService } from '../../../../global-drawer/global-drawer.service';
import { ContentEvent, ContentService } from '@soleran/ngx-content';

@Component({
	selector: 'app-chart-view-modal',
	templateUrl: './chart-view-modal.component.html',
	styleUrl: './chart-view-modal.component.scss',
	encapsulation: ViewEncapsulation.None,
	host: {
		class: 'app-chart-view-modal'
	}
})
export class ChartViewModalComponent {
	readonly chartComponent = viewChild.required(ChartComponent);

	@Input()
	get config() {
		return this.chartConfig();
	}
	set config(value: ChartConfig) {
		this.chartConfig.set(value);
	}
	chartConfig = signal<ChartConfig | null>(null);

	@Input()
	set initialDepth(depth: ChartDepth | null) {
		this._initialDepth = depth;
	}
	private _initialDepth: ChartDepth | null = null;

	private _drawerService = inject(GlobalDrawerService);

	constructor(private _contentService: ContentService) {
		effect(
			() => {
				const chartComponent = this.chartComponent();
				if (!!this._initialDepth) {
					chartComponent.setDepth(this._initialDepth);
				}
			},
			{ allowSignalWrites: true }
		);
	}

	onClose(): void {
		this._drawerService.close();
	}

	onContentEvent(event: ContentEvent): void {
		this._contentService.handleEvent(event.contentType, event);
	}
}
