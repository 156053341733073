import { Component, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { IApp, IHardcodedRoute, ISuite } from '@soleran/contracts';
import { combineLatest, map, Observable, shareReplay } from 'rxjs';
import { tld } from 'src/app/shared/_functions/util';
import { SuiteStateService } from '../_services/suite-state.service';
import { GlobalDrawerService } from '../global-drawer/global-drawer.service';
import { NavigationService } from './navigation.service';
import { INavItems } from '@soleran/ngx-suite';

export interface INavConfig {
	suites: INavSuite[];
}
export interface INavSuite extends Partial<ISuite> {
	apps: Partial<IApp[]>;
}
export type INavItem = (INavSuite & { type: 'suite' }) | (IHardcodedRoute & { type: 'hardcoded' });

@Component({
	selector: 'app-navigation',
	templateUrl: './navigation.component.html',
	styleUrls: ['./navigation.component.scss'],
	encapsulation: ViewEncapsulation.None,
	host: {
		class: 'app-navigation'
	}
})
export class NavigationComponent {
	public tld = tld;

	readonly admin_routes = [
		{ path: '/admin/object', name: 'Object', icon: 'deployed_code' },
		{ path: '/admin/navigation', name: 'Navigation', icon: 'apps' },
		{ path: '/admin/dashboard', name: 'Dashboard', icon: 'dashboard' },
		{ path: '/admin/template', name: 'Template', icon: 'design_services' }
	];

	isAdminMode = this._navService.isAdminMode;

	navOpened = this._navService.isOpen;
	navForceOverlay = this._navService.forceOverlay;
	navIsOverlay = this._navService.isOverlay;

	globalDrawerOpened = this._globalDrawerService.isOpen;
	globalDrawerMode = this._globalDrawerService.mode;

	subNavSuites$ = this._suiteStateService.suites$.pipe(
		map((suites) => suites.filter((suite) => !!suite.isSubNav) as INavSuite[]),
		shareReplay(1)
	);
	standaloneSuites$ = this._suiteStateService.suites$.pipe(
		map((suites) => suites.filter((suite) => !suite.isSubNav) as INavSuite[]),
		shareReplay(1)
	);
	hardcodedRoutes$ = this._suiteStateService.hardcodedRoutes$;
	combinedSuites$ = this._initSuites$();

	constructor(
		private _router: Router,
		private _navService: NavigationService,
		private _globalDrawerService: GlobalDrawerService,
		private _suiteStateService: SuiteStateService
	) {}

	onItemClick(
		item: IApp | INavSuite | (Omit<IHardcodedRoute, '_id' | 'index'> & { type: 'hardcoded' })
	): void {
		if (this._isApp(item)) {
			switch (item.navConfig.type) {
				case 'Link': {
					window.location.href = item.navConfig.link ?? '';
					break;
				}
				case 'Report': {
					this._navigateToReport(item.navConfig.reportId);
					break;
				}
				case 'Dashboard': {
					this._router.navigate(['dashboard', item.navConfig.dashboardId]);
					break;
				}
			}
		} else if (this._isSuite(item)) {
			if (item.apps && item.apps.length > 0) {
				const app = item.apps[0];
				if (app && app.navConfig) {
					switch (app.navConfig.type) {
						case 'Link': {
							window.location.href = app.navConfig.link ?? '';
							break;
						}
						case 'Report': {
							this._navigateToReport(app.navConfig.reportId);
							break;
						}
						case 'Dashboard': {
							this._router.navigate(['/']);
							break;
						}
					}
				}
			}
		} else if (this._isHardcodedRoute(item)) {
			this._router.navigate(['/', item.path]);
		}
		if (this.navIsOverlay()) this.closeNav();
	}

	onBackdropClick(): void {
		if (this.navOpened() && this.navIsOverlay()) {
			this.closeNav();
		}
		if (this.globalDrawerOpened()) {
			this._globalDrawerService.close();
		}
	}

	openNav(): void {
		this._navService.open();
	}

	closeNav(delay: number = 150): void {
		if (!!delay) {
			setTimeout(() => {
				this._navService.close();
			}, delay);
		} else {
			this._navService.close();
		}
	}

	toggleNav(state?: boolean): void {
		this._navService.toggle(state);
	}

	private _navigateToReport(reportId: string): void {
		this._router.navigate(['report', reportId]).catch((error) => {
			console.error('Navigation error:', error);
			alert('Failed to load the report. Please check the Report linked or try again later.');
		});
	}

	private _initSuites$(): Observable<INavItem[]> {
		return combineLatest([
			this._suiteStateService.suites$,
			this._suiteStateService.apps$,
			this.hardcodedRoutes$
		]).pipe(
			map(([suites, apps, hardcodedRoutes]) => {
				// Map suites to include apps, using combineLatest for error handling
				const appLookup = new Map<string, IApp>(apps.map((app) => [app.id, app]));
				const suitesWithApps = suites.map((suite) => {
					const apps = suite.appIds.reduce((result, appId) => {
						const app = appLookup.get(appId) ?? null;
						if (!!app) result.push(app);
						return result;
					}, [] as IApp[]);
					return { ...suite, type: 'suite', apps } as INavItem;
				});
				// Combine hardcoded routes and suites with apps
				const navItems = [
					...hardcodedRoutes.map((hcRoute) => ({ ...hcRoute, type: 'hardcoded' } as INavItem)),
					...suitesWithApps
				].sort((a, b) => a.index - b.index);

				// Return only hardcoded routes if no suites exist
				return navItems.length > 0
					? navItems
					: hardcodedRoutes
							.map((hcRoute) => ({ ...hcRoute, type: 'hardcoded' } as INavItem))
							.sort((a, b) => a.index - b.index);
			})
		);
	}

	private _isApp(obj: any): obj is IApp {
		return 'navConfig' in obj;
	}

	private _isSuite(obj: any): obj is INavSuite {
		return 'apps' in obj && Array.isArray(obj.apps);
	}

	private _isHardcodedRoute(obj: any): obj is Omit<IHardcodedRoute, '_id' | 'index'> {
		return 'path' in obj && !('navConfig' in obj);
	}
}
