import { Injectable } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Store } from '@ngrx/store';
import { BaseContentService, Content, IContent, IContentConfig } from '@soleran/contracts';
import {
	FormMode,
	IPageBuilderState,
	LayoutFormService,
	selectFormMode,
	selectModule,
	selectRecord
} from '@soleran/ngx-layout';
import { ModuleService } from '@soleran/ngx-module';
import { combineLatest, map, mergeMap, Observable, of, take, tap } from 'rxjs';
import { GlobalDrawerService } from '../../global-drawer/global-drawer.service';
import { FieldModalComponent } from '../modals/field/field-modal.component';

@Injectable({
	providedIn: 'root'
})
export class FieldContentService extends BaseContentService {
	moduleCache: Record<string, { module: any, timestamp: number }> = {};
	constructor(
		private _moduleService: ModuleService,
		private _store: Store<IPageBuilderState>,
		private _formService: LayoutFormService,
		private _drawerService: GlobalDrawerService
	) {
		super();
	}

	override canHandle(type: Content): boolean {
		return type === Content.Field;
	}

	override getInputs(contentId: any): Observable<any> {
		const moduleId = contentId.moduleId;
		const fieldId = contentId.fieldId;
		const recordId = contentId.recordId;
		const record$ = recordId ? this._store.select(selectRecord) : of(undefined);
		const module$ = this._getModule(moduleId);
		const form = this._formService.get();
		const formMode$ = this._store
			.select(selectFormMode)
			.pipe(map((mode) => (mode === FormMode.Edit ? 'edit' : 'readonly')));

		const inputs = combineLatest([record$, module$, formMode$]).pipe(
			take(1),
			map(([record, module, mode]) => {
				const field = module.fields?.find((field) => field.id === fieldId);
				if (!field) {
					throw new Error('Failed to find field with id: ' + fieldId);
				}

				const value = record ? record[field.id] : undefined;

				let control = form?.get(field.id) as FormControl;

				if (!control) {
					const disabled = false;
					control = new FormControl({ value: value ?? '', disabled: disabled });
					form?.addControl(field.id, control);
				} else {
					// If the control exists, update its value
					control.setValue(value ?? '');
					control.enable(); // Optionally re-enable the control if necessary
				}

				const inputs = {
					field,
					control,
					form,
					mode
				};

				return inputs;
			})
		);

		return inputs;
	}
	private _getModule(moduleId) {
		const currentTime = Date.now();

		if (this.moduleCache[moduleId] && (currentTime - this.moduleCache[moduleId].timestamp) < 60000) {
			return of(this.moduleCache[moduleId].module);
		}
		const module$ = this._moduleService.get(moduleId).pipe(
			tap(module => {
				this.moduleCache[moduleId] = { module, timestamp: currentTime };
			})
		);
		return module$;
	}

	override get = (config?: IContentConfig) => {
		const moduleId = config?.moduleId;
		if (!moduleId) return of([]);
		return this._moduleService.get(moduleId).pipe(
			map((module) => {
				const fields = module.fields;
				const contents = fields?.map((field) => {
					const identifier = {
						moduleId: module.id,
						fieldId: field.id
					};
					const content: IContent = {
						id: identifier,
						type: Content.Field,
						display: field.name
					};
					return content;
				});
				return contents ?? [];
			})
		);
	};

	override getOne = (id: string, config?: IContentConfig) => {
		const moduleId = config?.moduleId;
		if (!moduleId) return of(null);
		return this._moduleService.get(moduleId).pipe(
			map((module) => {
				if (!module) throw Error(`Content - Module with id ${config.moduleId} not found.`);
				const field = module.fields?.find((field) => field.id === id);
				if (!field) throw Error(`Content - Field with id ${id} not found.`);
				return { module, field };
			}),
			map(({ module, field }) => {
				const identifier = {
					moduleId: module.id,
					fieldId: field.id
				};
				const content: IContent = {
					id: identifier,
					type: Content.Field,
					display: field.name
				};
				return content;
			})
		);
	};
	override create = (config: any) => {
		const module$ = this._store.select(selectModule);
		return module$.pipe(
			take(1),
			mergeMap((module) => {
				const inputs = {
					module
				};
				return this._drawerService
					.open(FieldModalComponent, inputs)
					.pipe(map((field) => ({ module, field })));
			}),
			mergeMap(({ module, field }) => {
				if (field) {
					const updateModule = structuredClone(module);
					updateModule.fields.push(field);
					return this._moduleService.update(updateModule.id, updateModule);
				} else return of(undefined);
			})
		);
	};

	override update = (config: any) => {
		const moduleId = config.moduleId;
		const fieldId = config.fieldId;
		const module$ = this._store.select(selectModule);

		return module$.pipe(
			take(1),
			mergeMap((module) => {
				const field = module.fields.find((f) => f.id === fieldId);
				const inputs = {
					module,
					field
				};
				return this._drawerService
					.open(FieldModalComponent, inputs)
					.pipe(map((field) => ({ module, field })));
			}),
			mergeMap(({ module, field }) => {
				if (field) {
					const updateModule = structuredClone(module);
					const updateIndex = updateModule.fields.findIndex((f) => f.id === field.id);
					if (updateIndex === -1)
						throw Error('Failed to find field index for field with id ' + field.id);
					updateModule.fields[updateIndex] = field;
					return this._moduleService.update(updateModule.id, updateModule).pipe(map((_) => config));
				} else return of(undefined);
			})
		);
	};
}
