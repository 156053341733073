<app-topbar class="topbar">
	<div class="topbar-content">
		<button mat-icon-button (click)="toggleNav()" aria-label="Toggle sidenav" type="button">
			<mat-icon aria-label="Side nav toggle icon">menu</mat-icon>
		</button>
		@if(isAdminMode()) {
		<span>Admin</span>
		}
	</div>
</app-topbar>
<mat-sidenav-container
	[ngClass]="isAdminMode() ? 'admin-mode' : 'default-mode'"
	(backdropClick)="onBackdropClick()"
	class="sidenav-container">
	<!--Navigation-->
	<mat-sidenav
		fixedInViewport
		[attr.role]="navIsOverlay() ? 'dialog' : 'navigation'"
		[mode]="navIsOverlay() ? 'over' : 'side'"
		[opened]="navOpened()"
		[class.is-overlay]="navIsOverlay()"
		disableClose
		class="navigation">
		@if(!isAdminMode()) {
		<div [routerLink]="['/home']" class="brand-container">
			<app-logo />
		</div>
		<ng-container
			*ngIf="combinedSuites$ | async as combinedNavItems; else loadingNav"
			[ngTemplateOutlet]="mainNavigation"
			[ngTemplateOutletContext]="{ $implicit: combinedNavItems }" />
		} @else {
		<ng-container [ngTemplateOutlet]="adminNavigation" />
		}
	</mat-sidenav>
	<!--Global Drawer-->
	<mat-sidenav
		fixedInViewport
		[opened]="globalDrawerOpened()"
		[mode]="globalDrawerMode()"
		position="end"
		disableClose
		class="global-drawer">
		<app-global-drawer />
	</mat-sidenav>
	<mat-sidenav-content class="nav-content">
		<div #scrollContent class="scrollable">
			<ng-content />
		</div>
	</mat-sidenav-content>
</mat-sidenav-container>

<ng-template #loadingNav>
	<div class="nav-shimmer-wrapper">
		@for (_ of [].constructor(5); let i = $index; track i) {
		<div class="nav-shimmer-item">
			<sol-shimmer class="nav-shimmer-icon" />
			<sol-shimmer class="nav-shimmer-text" />
		</div>
		}
	</div>
</ng-template>

<ng-template #mainNavigation let-navItems>
	<mat-nav-list class="nav-list">
		<mat-accordion>
			@for(item of navItems; track item._id) {
			<ng-container *ngIf="item.type === 'suite' && item.isSubNav; else nonSubNavTemplate">
				<mat-expansion-panel>
					<mat-expansion-panel-header>
						<mat-panel-title>
							<mat-icon>{{ item.icon }}</mat-icon>
							<span>{{ item.name }}</span>
						</mat-panel-title>
					</mat-expansion-panel-header>
					<div class="nav-expansion-panel-body">
						<mat-divider [vertical]="true" />
						<div class="nav-expansion-panel-list-item-container">
							<mat-list-item
								class="nav-list-item"
								*ngFor="let app of item.apps"
								(click)="onItemClick(app)">
								<div class="nav-list-item-text">
									<span>{{ app?.name }}</span>
									<span style="flex: 1 1 auto"></span>
									<mat-icon>chevron_right</mat-icon>
								</div>
							</mat-list-item>
						</div>
					</div>
				</mat-expansion-panel>
			</ng-container>
			<ng-template #nonSubNavTemplate>
				<div class="nav-item">
					<a mat-button (click)="onItemClick(item)">
						<mat-icon>{{ item.icon }}</mat-icon>
						<span>{{ item.name }}</span>
					</a>
				</div>
			</ng-template>
			}
		</mat-accordion>
	</mat-nav-list>
</ng-template>

<ng-template #adminNavigation>
	<mat-nav-list class="route-list">
		<a
			mat-list-item
			(click)="closeNav(0)"
			[routerLink]="['/']"
			matTooltip="Home"
			[matTooltipDisabled]="navOpened()"
			matTooltipPosition="right"
			class="route-item">
			<div class="route-item-content">
				<mat-icon class="route-icon">home</mat-icon>
				<span class="route-title">Home</span>
			</div>
		</a>
		@for (route of admin_routes; track route.path) {
		<a
			mat-list-item
			(click)="closeNav()"
			[routerLink]="route.path"
			routerLinkActive="active"
			[routerLinkActiveOptions]="{ exact: false }"
			[matTooltip]="route.name"
			[matTooltipDisabled]="navOpened()"
			matTooltipPosition="right"
			class="route-item">
			<div class="route-item-content">
				<mat-icon class="route-icon material-symbols-outlined no-fill">
					{{ route.icon }}
				</mat-icon>
				<span class="route-title">{{ route.name }}</span>
			</div>
		</a>
		}
	</mat-nav-list>
</ng-template>
