import { Subject, share, filter, startWith, switchMap, takeUntil, Observable, merge } from 'rxjs';
import * as i0 from '@angular/core';
import { InjectionToken, Injectable, NgModule } from '@angular/core';
import { DEFAULT_NAMESPACE, StreamClientEmitType } from '@soleran/contracts';
import { io } from 'socket.io-client';
import * as i2 from '@angular/common/http';
import * as i3 from '@soleran/ngx-auth';

/**@important The `dispose` method should be called when done with the object. */
class EventSocket {
  get active() {
    return this._sharedSocket.active;
  }
  get connected() {
    return this._sharedSocket.connected;
  }
  /**The scoped namespace to use.
   * @note This namespace is multi-tenant based on the current user.
   * @default DEFAULT_NAMESPACE
   */
  get namespace() {
    return this._sharedSocket.namespace;
  }
  /**The rooms assigned to this socket instance.*/
  get rooms() {
    return [...this._roomStore.values()];
  }
  /**Determines if an emission without any rooms (global) will be received.
   * @default true
   */
  get allowGlobal() {
    return this._allowGlobal;
  }
  set allowGlobal(value) {
    this._allowGlobal = value;
  }
  constructor(sourceEventSocket, options) {
    this._roomStore = new Set();
    this._allowGlobal = true;
    this._disposeChild$ = new Subject();
    this._sharedSocket = sourceEventSocket;
    this._sharedSocket._incrementRefCount();
    if (!!options?.room) this.joinRoom(options.room);
    if (options?.allowGlobal !== undefined) {
      this._allowGlobal = options.allowGlobal;
    }
  }
  reconnect() {
    this._sharedSocket._reconnect();
  }
  joinRoom(room) {
    if (Array.isArray(room)) room.forEach(r => this._roomStore.add(r));else this._roomStore.add(room);
    this._sharedSocket._joinRoom(room);
  }
  leaveRoom(room) {
    if (Array.isArray(room)) room.forEach(r => this._roomStore.delete(r));else this._roomStore.delete(room);
    this._sharedSocket._leaveRoom(room);
  }
  emit(options) {
    this._sharedSocket._emit(options);
  }
  listen(...events) {
    return this._sharedSocket._listen(this._disposeChild$, ...events).pipe(this._roomsFilter(), share());
  }
  listenAny() {
    return this._sharedSocket._listenAny(this._disposeChild$).pipe(this._roomsFilter(), share());
  }
  dispose() {
    this._disposeChild$.next();
    this._sharedSocket._decrementRefCount();
  }
  _roomsFilter() {
    return filter(streamData => {
      const rooms = streamData.metadata.rooms;
      if (rooms?.length) {
        if (this._roomStore.size > 0) {
          for (const room of rooms) {
            if (this._roomStore.has(room)) {
              return true;
            }
          }
        }
        return false;
      }
      return this.allowGlobal;
    });
  }
}

/**@internal*/
class EventSocketSource {
  get active() {
    return !!this._socket?.active;
  }
  get connected() {
    return !!this._socket?.connected;
  }
  constructor(eventService, authService, namespace) {
    this.namespace = DEFAULT_NAMESPACE;
    this._socket = null;
    this._tenantId = null;
    this._roomStore = new Set();
    this._refCount = 0;
    this._forceReconnect$ = new Subject();
    this._renewListeners$ = new Subject();
    this._disposeSource$ = new Subject();
    if (!!namespace) this.namespace = namespace;
    this._authService = authService;
    this._eventService = eventService;
    this._forceReconnect$.pipe(startWith(void 0), switchMap(() => this._authService.onCurrentUser), takeUntil(this._disposeSource$)).subscribe(userToken => {
      if (!userToken || userToken.claims.account_id != this._tenantId) {
        if (!!this._socket) {
          this._socket.removeAllListeners();
          this._socket.disconnect();
        }
        if (!!userToken) {
          this._tenantId = userToken.claims.account_id;
          const socketUrl = `${eventService.apiBaseUrl}${eventService.apiBaseUrl.endsWith('/') ? '' : '/'}${this.namespace}-${this._tenantId}`;
          this._socket = io(socketUrl, {
            path: '/event',
            auth: next => next({
              token: this._authService.currentUser()?.token
            })
          });
          if (this._roomStore.size > 0) this._joinRoom([...this._roomStore.keys()]);
        }
      } else if (!!this._socket) {
        this._socket.disconnect();
        const managerClosed = !this._socket.id && this._roomStore.size > 0;
        this._socket.connect();
        if (managerClosed) this._joinRoom([...this._roomStore.keys()]);
      }
      this._renewListeners$.next();
    });
  }
  _incrementRefCount() {
    this._refCount++;
  }
  _decrementRefCount() {
    this._refCount--;
    if (this._refCount < 1) this._dispose();
  }
  _reconnect() {
    this._forceReconnect$.next();
  }
  _joinRoom(room) {
    this._storeRoom(room);
    this._emitJoinRoom(room);
  }
  _storeRoom(room) {
    if (Array.isArray(room)) room.forEach(r => this._roomStore.add(r));else this._roomStore.add(room);
  }
  _emitJoinRoom(room) {
    this._socket?.emit(StreamClientEmitType.JOIN_ROOM, room);
  }
  _leaveRoom(room) {
    if (Array.isArray(room)) room.forEach(r => this._roomStore.delete(r));else this._roomStore.delete(room);
    this._socket?.emit(StreamClientEmitType.LEAVE_ROOM, room);
  }
  _emit(options) {
    if (!this._socket || !this.connected && !this.active) throw new Error('Socket Disconnected');
    const namespaceData = options?.namespaceData;
    const rooms = options.rooms ?? undefined;
    let except = options?.except;
    if (options?.broadcast ?? true) {
      except = options?.except ?? [];
      except.push(this._socket.id);
    }
    this._socket.emit(StreamClientEmitType.EMIT, {
      event: options.event,
      data: options.data,
      namespaceData: {
        name: namespaceData?.name ?? this.namespace,
        tenant: namespaceData?.tenant ?? this._tenantId
      },
      rooms,
      except
    });
  }
  _listen(disposeChild$, ...events) {
    if (!this._socket || !this.connected && !this.active) throw new Error('Socket Disconnected');
    return this._renewListeners$.pipe(startWith(void 0), switchMap(() => new Observable(observer => {
      const socket = this._socket;
      events.forEach(event => {
        socket.on(event, streamData => {
          observer.next(streamData);
        });
      });
      return function unsubscribe() {
        events.forEach(event => {
          if (socket.listeners(event).length < 2) socket.off(event);
        });
        observer.complete();
      };
    })), takeUntil(merge(this._disposeSource$, disposeChild$)), share());
  }
  _listenAny(disposeChild$) {
    if (!this._socket || !this.connected && !this.active) throw new Error('Socket Disconnected');
    return this._renewListeners$.pipe(startWith(void 0), switchMap(() => {
      return new Observable(observer => {
        const socket = this._socket;
        socket.onAny((_, data) => {
          observer.next(data);
        });
        return function unsubscribe() {
          if (socket.listenersAny().length < 2) socket.offAny();
          observer.complete();
        };
      });
    }), takeUntil(merge(this._disposeSource$, disposeChild$)), share());
  }
  _dispose() {
    this._socket?.removeAllListeners();
    this._socket?.disconnect();
    this._disposeSource$.next();
    this._socket = null;
    this._tenantId = null;
  }
}
const EventModuleConfig = new InjectionToken('Event Module Configuration Token');
class EventService {
  get activeNamespaces() {
    return [...this._namespaceMap.keys()];
  }
  constructor(_config, _httpClient, _authService) {
    this._config = _config;
    this._httpClient = _httpClient;
    this._authService = _authService;
    this.apiBaseUrl = this._config.apiBaseUrl;
    this.apiPath = `${this.apiBaseUrl}/api/event`;
    this._namespaceMap = new Map();
  }
  getStatus() {
    return this._httpClient.get(`${this.apiPath}/status`);
  }
  getSocket(options) {
    const namespace = options?.namespace ?? DEFAULT_NAMESPACE;
    const existingSocket = this._namespaceMap.get(namespace);
    if (!existingSocket) {
      const socket = new EventSocketSource(this, this._authService, namespace);
      this._namespaceMap.set(namespace, socket);
      return new EventSocket(socket, {
        room: options?.room,
        allowGlobal: options?.allowGlobal
      });
    } else return new EventSocket(existingSocket, {
      room: options?.room,
      allowGlobal: options?.allowGlobal
    });
  }
  static {
    this.ɵfac = function EventService_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || EventService)(i0.ɵɵinject(EventModuleConfig), i0.ɵɵinject(i2.HttpClient), i0.ɵɵinject(i3.AuthService));
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: EventService,
      factory: EventService.ɵfac
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(EventService, [{
    type: Injectable
  }], () => [{
    type: EventModuleConfig
  }, {
    type: i2.HttpClient
  }, {
    type: i3.AuthService
  }], null);
})();
class EventModule {
  static forRoot(config) {
    return {
      ngModule: EventModule,
      providers: [{
        provide: EventModuleConfig,
        useValue: config
      }, EventService]
    };
  }
  static {
    this.ɵfac = function EventModule_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || EventModule)();
    };
  }
  static {
    this.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
      type: EventModule
    });
  }
  static {
    this.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({});
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(EventModule, [{
    type: NgModule
  }], null, null);
})();

/*
 * Public API Surface of profile
 */

/**
 * Generated bundle index. Do not edit.
 */

export { EventModule, EventModuleConfig, EventService, EventSocket };
