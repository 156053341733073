import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ChartBuilderModule, ChartModule } from '@soleran/ngx-chart';
import { ScrollShadowContainerModule } from '@soleran/ngx-common';
import { FieldFormModule } from '@soleran/ngx-control';
import { ModuleFormModule } from '@soleran/ngx-module';
import { RecordPageModule } from '../../pages/module/record/record-page/record-page.module';
import { CONTENT_MODAL_COMPONENTS } from './content-registry';
import { ContentMaterialModule } from './content.module.material';

const components = [...CONTENT_MODAL_COMPONENTS];

@NgModule({
	declarations: components,
	imports: [
		CommonModule,
		FormsModule,
		ReactiveFormsModule,
		ScrollShadowContainerModule,
		ContentMaterialModule,
		ChartModule,
		FieldFormModule,
		ModuleFormModule,
		ChartBuilderModule,
		RecordPageModule
	],
	exports: components
})
export class ContentModule {}
