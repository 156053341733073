<mat-toolbar [color]="color">
	<ng-content />
	<div class="user-info">
		<sol-user-nameplate class="nameplate" />
		<sol-user-menu #userMenu>
			<button mat-button [routerLink]="['/account']" (click)="userMenu.toggle(false)">
				<mat-icon>admin_panel_settings</mat-icon>
				<span>Account Settings</span>
			</button>
			<button mat-button (click)="onBuildAndConfigureClick(); userMenu.toggle(false)">
				<mat-icon>build</mat-icon>
				<span>Build & Configure</span>
			</button>
			<button
				mat-button
				*ngIf="!isProduction"
				(click)="copyAccessToken(); userMenu.toggle(false)"
				color="accent">
				<mat-icon>token</mat-icon>
				<span>Copy Token</span>
			</button>
			<button mat-button (click)="goToChat()">
				<mat-icon class="material-symbols-outlined">chat_bubble</mat-icon>
				<span>Chat</span>
				<!-- <span *ngIf="unreadCount > 0" class="unread-count">{{ unreadCount }}</span> -->
			</button>
			<button mat-button [routerLink]="['/help']" (click)="userMenu.toggle(false)">
				<mat-icon class="material-symbols-outlined">help</mat-icon>
				<span>Help</span>
			</button>
		</sol-user-menu>
	</div>
</mat-toolbar>
