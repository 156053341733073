import { NgModule } from '@angular/core';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { MatIconRegistry } from '@angular/material/icon';
import { BrowserModule, DomSanitizer } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { tld } from './shared/_functions/util';
import { AppRoutingModule } from './app-routing.module';
import { AppServiceModule } from './app-service.module';
import { AppComponent } from './app.component';
import { AppMaterialModule } from './app.module.material';
import { SharedModule } from './shared/shared.module';

@NgModule({
	declarations: [AppComponent],
	imports: [
		BrowserModule,
		BrowserAnimationsModule,
		AppMaterialModule,
		AppRoutingModule,
		AppServiceModule,
		SharedModule
	],
	providers: [{ provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: { appearance: 'outline' } }],
	bootstrap: [AppComponent]
})
export class AppModule {
	constructor(private _reg: MatIconRegistry, private _san: DomSanitizer) {
		this._registerIcon('soleran', 'soleran_logo.svg');
	}

	private _registerIcon(name: string, fileName: string) {
		const url = this._formatStaticFileUrl(fileName);
		this._reg.addSvgIcon(name, this._san.bypassSecurityTrustResourceUrl(url));
	}

	private _formatStaticFileUrl(file: string) {
		const { protocol } = location;
		return `${protocol}//static.soleran.${tld}/system/image/${file}`;
	}
}
