import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';

const modules = [
	MatButtonModule,
	MatIconModule,
	MatSidenavModule,
	MatToolbarModule,
	MatListModule,
	MatExpansionModule,
	MatTooltipModule
];

@NgModule({
	imports: modules,
	exports: modules
})
export class NavigationMaterialModule {}
