import { isDevMode, NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { AccountModule } from '@soleran/ngx-account';
import { AppModule as ApplicationModule } from '@soleran/ngx-app';
import { AuthModule } from '@soleran/ngx-auth';
import { UserDialogModule } from '@soleran/ngx-auth-utility';
import { ChartModule } from '@soleran/ngx-chart';
import { ChatModule } from '@soleran/ngx-chat';
import { ContentModule } from '@soleran/ngx-content';
import { DirectoryModule } from '@soleran/ngx-directory';
import { EventModule } from '@soleran/ngx-event';
import { FileModule } from '@soleran/ngx-file';
import { ExportModule, ImportModule } from '@soleran/ngx-import-export';
import { LayoutModule } from '@soleran/ngx-layout';
import { ModuleModule } from '@soleran/ngx-module';
import { PageModule } from '@soleran/ngx-page';
import { ProfileModule } from '@soleran/ngx-profile';
import { RecordModule } from '@soleran/ngx-record';
import { ReportConfigModule, ReportLogModule, ReportModule } from '@soleran/ngx-report';
import { ReportBuilderModule } from '@soleran/ngx-report-builder';
import { RoleModule } from '@soleran/ngx-role';
import { HardcodedRoutesModule, SuiteModule } from '@soleran/ngx-suite';
import { TemplateModule } from '@soleran/ngx-template';
import { apiKey, authDomain, tld } from 'src/app/shared/_functions/util';
import { CONTENT_SERVICES } from './shared/content/content-registry';
import { IconPickerModule } from '@soleran/ngx-common';

const REPORT_API_BASE_URL = `https://report.soleran.${tld}`;
// const REPORT_API_BASE_URL = `http://localhost:3000`;
const MODULE_API_BASE_URL = `https://module.soleran.${tld}`;
// const MODULE_API_BASE_URL = `http://localhost:3000`;
const RECORD_API_BASE_URL = `https://record.soleran.${tld}`;
// const RECORD_API_BASE_URL = `http://localhost:3000`;

@NgModule({
	imports: [
		StoreModule.forRoot({}, {}),
		EffectsModule.forRoot([]),
		StoreDevtoolsModule.instrument({
			name: 'Soleran',
			maxAge: 25,
			connectInZone: true,
			logOnly: !isDevMode()
		}),
		//Soleran Libraries
		AccountModule.forRoot({ apiBaseUrl: `https://account.soleran.${tld}` }),
		ApplicationModule.forRoot({ apiBaseUrl: `https://app.soleran.${tld}` }),
		AuthModule.forRoot({
			identityConfig: {
				apiKey: apiKey,
				authDomain: authDomain
			},
			apiBaseUrl: `https://auth.soleran.${tld}`,
			loginRoute: '/login',
			userManageRoute: '/user'
		}),
		ChartModule.forRoot({
			apiBaseUrl: `https://chart.soleran.${tld}`
		}),
		ChatModule.forRoot({ apiBaseUrl: `https://chat.soleran.${tld}` }),
		ContentModule.registerContentService(CONTENT_SERVICES),
		DirectoryModule.forRoot({ apiBaseUrl: `https://directory.soleran.${tld}` }),
		EventModule.forRoot({ apiBaseUrl: `https://event.soleran.${tld}` }),
		ExportModule,
		FileModule.forRoot({ apiBaseUrl: `https://file.soleran.${tld}` }),
		HardcodedRoutesModule,
		IconPickerModule.forRoot({ apiBaseUrl: `https://static.soleran.${tld}` }),
		ImportModule.forRoot({ apiBaseUrl: `https://import.soleran.${tld}` }),
		PageModule.forRoot({ apiBaseUrl: `https://page.soleran.${tld}` }),
		ProfileModule.forRoot({ apiBaseUrl: `https://profile.soleran.${tld}` }),
		LayoutModule.forRoot({ apiBaseUrl: `https://layout.soleran.${tld}` }),
		ModuleModule.forRoot({ apiBaseUrl: MODULE_API_BASE_URL }),
		RecordModule.forRoot({ apiBaseUrl: RECORD_API_BASE_URL }),
		ReportBuilderModule.forRoot({ apiBaseUrl: REPORT_API_BASE_URL }),
		ReportConfigModule.forRoot({ apiBaseUrl: REPORT_API_BASE_URL }),
		ReportLogModule.forRoot({ apiBaseUrl: REPORT_API_BASE_URL }),
		ReportModule.forRoot({ apiBaseUrl: REPORT_API_BASE_URL }),
		RoleModule.forRoot({ apiBaseUrl: `https://role.soleran.${tld}` }),
		SuiteModule.forRoot({ apiBaseUrl: `https://suite.soleran.${tld}` }),
		TemplateModule.forRoot({ apiBaseUrl: `https://template.soleran.${tld}` }),
		UserDialogModule
	]
})
export class AppServiceModule {}
