<mat-toolbar class="acem-top-bar">{{ chartConfig()?.name ?? '' }}</mat-toolbar>
<div sol-chart-builder #builder [(chartConfig)]="chartConfig">
	<div class="acem-body-wrapper">
		<sol-chart-builder-preview (contentEvent)="onContentEvent($event)" #preview />
		<mat-tab-group preserveContent dynamicHeight>
			<mat-tab label="Setup">
				<div sol-scroll-shadow-container class="acem-setup-wrapper">
					<sol-chart-builder-setup />
				</div>
			</mat-tab>
			<mat-tab label="Customize">
				<div sol-scroll-shadow-container class="acem-customize-wrapper">
					<sol-chart-builder-customize />
				</div>
			</mat-tab>
		</mat-tab-group>
	</div>
</div>
<mat-toolbar class="acem-bottom-bar">
	<button mat-button (click)="onCancel()">Cancel</button>
	<button mat-button color="primary" (click)="onSubmit()">Save</button>
</mat-toolbar>
