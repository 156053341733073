import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { UserMenuModule, UserNameplateModule } from '@soleran/ngx-auth-utility';
import { ShimmerModule } from '@soleran/ngx-common';
import { LogoModule } from '../logo/logo.module';
import { NavigationComponent } from './navigation.component';
import { NavigationMaterialModule } from './navigation.module.material';
import { TopbarComponent } from './topbar/topbar.component';
import { GlobalDrawerModule } from '../global-drawer/global-drawer.module';

const components = [NavigationComponent, TopbarComponent];

@NgModule({
	declarations: components,
	imports: [
		CommonModule,
		RouterModule,
		NavigationMaterialModule,
		UserMenuModule,
		UserNameplateModule,
		ShimmerModule,
		GlobalDrawerModule,
		LogoModule
	],
	exports: components
})
export class NavigationModule {}
