import { Component } from '@angular/core';

@Component({
	selector: 'app-default-layout',
	template: `
		<app-navigation>
			<router-outlet />
		</app-navigation>
	`,
	styles: []
})
export class DefaultLayoutComponent {}
