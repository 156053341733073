import {
	Component,
	DestroyRef,
	inject,
	Input,
	signal,
	viewChild,
	ViewEncapsulation
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { DirectoryItemType, DirectoryRoot } from '@soleran/contracts';
import { ChartBuilderComponent, ChartConfig, ChartConfigService } from '@soleran/ngx-chart';
import { DirectoryService } from '@soleran/ngx-directory';
import { map, of, switchMap } from 'rxjs';
import { GlobalDrawerService } from '../../../../global-drawer/global-drawer.service';
import { ContentEvent, ContentService } from '@soleran/ngx-content';

@Component({
	selector: 'app-chart-editor-modal',
	templateUrl: './chart-editor-modal.component.html',
	styleUrl: './chart-editor-modal.component.scss',
	encapsulation: ViewEncapsulation.None,
	host: {
		class: 'app-chart-editor-modal'
	}
})
export class ChartEditorModalComponent {
	_chartBuilder = viewChild(ChartBuilderComponent);

	@Input()
	get config() {
		return this.chartConfig();
	}
	set config(value: ChartConfig) {
		this.chartConfig.set(value);
	}
	chartConfig = signal<ChartConfig | null>(null);

	private _destroyRef = inject(DestroyRef);
	private _drawerService = inject(GlobalDrawerService);
	private _chartConfigService = inject(ChartConfigService);
	private _directoryService = inject(DirectoryService);
	private _contentService = inject(ContentService);

	onCancel(): void {
		this._drawerService.close();
	}

	onContentEvent(event: ContentEvent): void {
		this._contentService.handleEvent(event.contentType, event);
	}

	onSubmit(): void {
		const chartConfig = this.chartConfig();
		if (!chartConfig) return;
		if (!chartConfig.id) {
			this._chartConfigService
				.create(chartConfig)
				.pipe(
					takeUntilDestroyed(this._destroyRef),
					switchMap((config) => {
						if (!!config) {
							return this._directoryService
								.create({
									type: DirectoryItemType.CHART,
									typeId: config.id,
									folderId: DirectoryRoot.CHART
								})
								.pipe(map(() => config));
						}
						return of(config);
					})
				)
				.subscribe((config) => {
					this._drawerService.close(config);
				});
		} else {
			this._chartConfigService
				.update(chartConfig)
				.pipe(takeUntilDestroyed(this._destroyRef))
				.subscribe((config) => {
					this._drawerService.close(config);
				});
		}
	}
}
