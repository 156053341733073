import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { AppModule } from './app/app.module';

platformBrowserDynamic()
	.bootstrapModule(AppModule)
	.catch((err) => console.error(err));

//Easter
(window as any).gravityMode = (modifier?: number) => {
	const buttons = document.querySelectorAll('button');
	const viewportWidth = window.innerWidth;
	const viewportHeight = window.innerWidth;
	const padding = 5;
	buttons.forEach((button) => {
		const rect = button.getBoundingClientRect();
		const clonedButton = button.cloneNode(true) as HTMLElement;
		clonedButton.style.position = 'absolute';
		if (+clonedButton.style.top > viewportHeight - padding) {
			clonedButton.style.top = `${viewportHeight - padding}px`;
		} else clonedButton.style.top = `${rect.top}px`;
		if (+clonedButton.style.left > viewportWidth - padding) {
			clonedButton.style.top = `${viewportWidth - padding}px`;
		} else clonedButton.style.left = `${rect.left}px`;
		clonedButton.style.width = `${rect.width}px`;
		clonedButton.style.height = `${rect.height}px`;
		clonedButton.style.zIndex = '1000';
		document.body.appendChild(clonedButton);
		button.style.visibility = 'hidden';
		let velocityX = Math.random() * (modifier || 40) - 20;
		let velocityY = Math.random() * (modifier || 40) - 20;
		let posX = rect.left;
		let posY = rect.top;
		function updatePosition(elapsedTime: number) {
			posX += velocityX * elapsedTime;
			posY += velocityY * elapsedTime;
			if (posX <= 0 + padding || posX + rect.width >= viewportWidth - padding) {
				velocityX = -velocityX;
			}
			if (posY <= 0 + padding || posY + rect.height >= viewportHeight - padding) {
				velocityY = -velocityY;
			}
			clonedButton.style.left = `${posX}px`;
			clonedButton.style.top = `${posY}px`;
		}
		function animate() {
			let lastTime = 0;
			function frame(time: number) {
				if (lastTime) {
					updatePosition((time - lastTime) / 1000);
				}
				lastTime = time;
				requestAnimationFrame(frame);
			}
			requestAnimationFrame(frame);
		}
		animate();
	});
};
