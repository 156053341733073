import { Component, ElementRef, Input } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { AuthService } from '@soleran/ngx-auth';
import { ChatService, GlobalsocketService } from '@soleran/ngx-chat';
import { Subject, Subscription, takeUntil } from 'rxjs';
import { environment } from 'src/environments/environment';
import { tld } from 'src/app/shared/_functions/util';

@Component({
	selector: 'app-topbar',
	templateUrl: './topbar.component.html',
	styleUrls: ['./topbar.component.scss']
})
export class TopbarComponent {
	@Input() title: string = '';
	@Input() color: string = 'primary'

	isProduction = !!environment.production;

	unreadCount: number = 0;
	currentUserId: string = '';
	private destroy$ = new Subject<void>();
	private _subs = new Subscription();
	accountId: string = '';

	constructor(
		private _snackbarService: MatSnackBar,
		private _authService: AuthService,
		private _chatService: ChatService,
		private globalSocketService: GlobalsocketService,
		private _router: Router,
		private elRef: ElementRef,
	) {
		const currentUser = this._authService.currentUser();
		if (currentUser) {
			this.currentUserId = currentUser.claims.user_id;
			this.fetchUnreadCount();
			this.subscribeToLiveUnreadCounts();
		}
	}

	getHeight() {
		return this.elRef.nativeElement.offsetHeight;
	}

	private fetchUnreadCount() {
		this._chatService.getUnreadCount(this.currentUserId).subscribe({
			next: (unreadCounts) => {
				if (Array.isArray(unreadCounts)) {
					this.unreadCount = unreadCounts.reduce((total, countObj) => total + countObj.count, 0);
				}
			},
			error: (err) => console.error('Error fetching unread count', err)
		});
	}

	private subscribeToLiveUnreadCounts() {
		this.globalSocketService.readReceipt$.pipe(takeUntil(this.destroy$)).subscribe({
			next: (readReceipts) => {
				if (readReceipts.data) {
					readReceipts.data.forEach((receipt: any) => {
						const roomIdParts = receipt.roomid.split('-');
						const actualRoomId = roomIdParts.length > 1 ? roomIdParts[1] : receipt.roomid;
						const author = receipt.author;
						receipt.users.forEach((user: any) => {
							const userId = Object.keys(user)[0];
							const status = user[userId];
							if (
								author !== this.currentUserId &&
								userId === this.currentUserId &&
								(status === 'sent' || status === 'received')
							) {
								this.unreadCount++;
							}
						});
					});
				}
			},
			error: (err) => console.error('Error reading readReceipts', err)
		});
	}

	onBuildAndConfigureClick() {
		this._router.navigate(['/admin']);
	}

	copyAccessToken(): void {
		const currentUser = this._authService.currentUser();
		if (!currentUser) return;
		navigator.clipboard.writeText(currentUser.token);
		this._snackbarService.open('Access token copied to clipboard.', 'Dismiss', {
			duration: 2500
		});
	}

	goToChat() {
		const _ = window.open(`https://soleran.${tld}/chat`, '_blank');
	}
}
